




















































































































// (window as any).fbq('track', 'Purchase', {currency: "USD", value: 30.00});
// (window as any).fbq('track', 'PageView');

import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { dispatchGetInvitedUsers } from '@/store/main/actions';
import { readInvitedUsers, readIsLoggedIn } from '@/store/main/getters';

@Component
export default class News extends Vue {
  // public inviteURL = "123ffff4";

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }


  public headers = [
    { text: '加入時間', sortable: false, value: 'time_created', align: 'left'},
    { text: 'Email', sortable: false, value: 'email', align: 'left'},
  ];

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get invitedUsers() {
    return readInvitedUsers(this.$store);
  }

  async mounted() {
    await dispatchGetConstants(this.$store);
    await dispatchGetInvitedUsers(this.$store);
  }

  copyTestingCode() {
    let testingCodeToCopy = document.querySelector('#testing-code') as HTMLInputElement;

    if (testingCodeToCopy) {
      testingCodeToCopy.setAttribute('type', 'text');    // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        alert('Unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden');

      var s = window.getSelection();
      if (s) {
        s.removeAllRanges();
      }
    }
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
